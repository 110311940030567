<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="checklistInstructions"
      hide-default-footer
      class="elevation-1"
      mobile-breakpoint="300"
    >
      <template v-slot:top>
        <div class="d-flex justify-space-between align-center px-6 py-3">
          <span class="mx-3 text-dark-50 mt-1 font-size-h5 font-weight-bolder"
            >Instructions</span
          >
          <button
            type="submit"
            class="btn btn-light-info"
            @click="() => showAddInstructionModal()"
          >
            New Item
          </button>
        </div>
      </template>

      <template v-slot:item.status="{ value }">
        <StatusColumn class="font-size-xs" v-if="value === 1" value="Enabled" />
        <StatusColumn class="font-size-xs" v-else value="Disabled" />
      </template>

      <template v-slot:item.instructions="{ value }">
        <InstructionTextAreaDialog
          :instructions="value"
          @save="handleSaveInstruction"
        />
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="() => showEditInstructionModal(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon small @click="() => removeInstruction(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <AddEditInstruction ref="instruction" />
  </div>
</template>

<script>
import AddEditInstruction from "@/own/components/warehouseManagement/qcChecklist/AddEditInstruction";
import {
  EDIT_CHECKLIST_INSTRUCTIONS,
  REMOVE_CHECKLIST_INSTRUCTIONS,
} from "@/core/services/store/qcChecklists.module";
import StatusColumn from "@/own/components/datatable/StatusColumn";
import InstructionTextAreaDialog from "@/own/components/warehouseManagement/qcChecklist/InstructionTextAreaDialog";
export default {
  name: "InstructionDatatable",
  components: { InstructionTextAreaDialog, StatusColumn, AddEditInstruction },
  computed: {
    checklistInstructions: function () {
      return this.$store.getters.getQCCHECKLISTSInstructions;
    },
    headers: function () {
      return [
        {
          text: "QC Category ID",
          value: "qc_category_id",
          align: "center",
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
        {
          text: "Instructions",
          value: "instructions",
          align: "left",
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        }, // px-1 max-w-350px text-wrap
        {
          text: "Status",
          value: "status",
          align: "left",
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
        {
          text: "Actions",
          value: "actions",
          align: "left",
          sortable: false,
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
      ];
    },
  },
  methods: {
    handleSaveInstruction(prevText, newText) {
      const item = this.checklistInstructions.find(
        (elem) => elem.instructions === prevText
      );

      const newItem = {
        ...item,
        instructions: newText,
      };
      const lastElementIndex = this.checklistInstructions.findIndex(
        (elem) => elem.id === item.id
      );

      this.$store.commit(EDIT_CHECKLIST_INSTRUCTIONS, {
        newItem,
        lastElementIndex,
      });
    },
    showEditInstructionModal(item) {
      const lastElementIndex = this.checklistInstructions.findIndex(
        (elem) => elem.id === item.id
      );
      this.$refs.instruction.initData(lastElementIndex, item);
      this.$refs.instruction.toggleModal();
    },
    showAddInstructionModal() {
      this.$refs.instruction.initData(null, {
        qc_category_id: null,
        instructions: null,
        status: null,
      });
      this.$refs.instruction.toggleModal();
    },
    removeInstruction(item) {
      this.$store.commit(REMOVE_CHECKLIST_INSTRUCTIONS, item);
    },
  },
};
</script>
